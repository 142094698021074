import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';
import { MatLegacyCardModule } from '@angular/material/legacy-card';

// TODO: Fix imports
import {
  ArticlePharmaceuticalIngredient,
  ArticlePharmaceuticalProperty,
} from '@mp/content-manager/artikelsuche/data-access';

import { CategoryType, categoryTypeDescriptions } from '../../../categories';
import { ArticleAttributes, ArticleCategoriesWithProperties } from '../../models';
import { CategoryCardComponent } from '../category-card/category-card.component';
import { KeyValue } from '../key-value-panel/key-value';
import { KeyValuePanelComponent } from '../key-value-panel/key-value-panel.component';

interface PharmaceuticalPropertyViewInfo {
  expansionKey: string;
  keyValues: KeyValue[];
}

@Component({
  selector: 'mpcm-tab-pharma',
  standalone: true,
  templateUrl: './tab-pharma.component.html',
  styleUrl: './tab-pharma.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyCardModule, CategoryCardComponent, KeyValuePanelComponent],
})
export class TabPharmaComponent {
  readonly categories: InputSignal<ArticleCategoriesWithProperties[]> =
    input.required<ArticleCategoriesWithProperties[]>();

  readonly pharmaceuticalProperties: InputSignal<ArticlePharmaceuticalProperty[]> =
    input.required<ArticlePharmaceuticalProperty[]>();

  readonly basePath: InputSignal<string[]> = input.required<string[]>();

  readonly articleAttributes: InputSignal<ArticleAttributes | undefined> = input<ArticleAttributes>();

  protected readonly arePharmaceuticalPropertiesHighlithed: Signal<boolean> = computed(
    () => !!this.articleAttributes()?.['pharmaceuticalProperties'],
  );

  protected readonly pharmaceuticalPropertiesViewInfos: Signal<PharmaceuticalPropertyViewInfo[]> = computed(() =>
    this.getPharmaceuticalPropertiesViewInfo(this.pharmaceuticalProperties()),
  );

  readonly CategoryType = CategoryType;

  protected readonly categoryTypeDescriptions: Record<CategoryType, string> = categoryTypeDescriptions;

  protected readonly CATEGORY_PROPERTIES_EXPANSION_STATE_KEY = 'CM_PHARMA_CATEGORY_PROPERTIES_EXPANDED';

  protected readonly PHARMA_PROPERTY_EXPANSION_STATE_KEY = 'CM_PHARMA_PROPERTY_EXPANDED';

  private getPharmaceuticalPropertiesViewInfo(
    articlePharmaceuticalProperties: ArticlePharmaceuticalProperty[],
  ): PharmaceuticalPropertyViewInfo[] {
    return articlePharmaceuticalProperties.map((property, index) => ({
      expansionKey: `${this.PHARMA_PROPERTY_EXPANSION_STATE_KEY}-${index}`,
      keyValues: this.getPharmaceuticalPropertyKeyValuesList(property),
    }));
  }

  private getPharmaceuticalPropertyKeyValuesList(pharmaProperty: ArticlePharmaceuticalProperty): KeyValue[] {
    const { dosageFormLongName, ingredients, totalDrugConcentration, totalDrugConcentrationUnit, laws } =
      pharmaProperty;

    const keyValues: KeyValue[] = [];

    if (dosageFormLongName) {
      keyValues.push({ key: 'Darreichungsform', value: dosageFormLongName });
    }

    if (ingredients) {
      for (const ingredient of ingredients) {
        keyValues.push({
          key: 'Wirkstoff',
          value: ingredient.ingredient,
          additionalValue: this.getDrugConcentrationPropertyValue(ingredient),
        });
      }
    }

    if (totalDrugConcentration) {
      keyValues.push({
        key: 'Gesamtmenge',
        value: this.getDrugConcentrationValue(totalDrugConcentration, totalDrugConcentrationUnit),
      });
    }

    if (laws) {
      keyValues.push(...this.getLawsPropertyKeyValuesList(laws));
    }

    return keyValues;
  }

  private getDrugConcentrationPropertyValue({
    drugConcentration,
    drugConcentrationUnit,
  }: ArticlePharmaceuticalIngredient): string {
    const localizedDrugConcentration: string = this.getDrugConcentrationValue(drugConcentration, drugConcentrationUnit);

    return localizedDrugConcentration ? `Dosis: ${localizedDrugConcentration}` : '';
  }

  private getDrugConcentrationValue(
    drugConcentration: number | undefined,
    drugConcentrationUnit: string | undefined,
  ): string {
    if (drugConcentration && drugConcentrationUnit) {
      const localizedDrugConcentration: string = drugConcentration.toLocaleString('de-DE');
      return `${localizedDrugConcentration} ${drugConcentrationUnit}`;
    }

    return '';
  }

  private getLawsPropertyKeyValuesList(laws: Record<string, string>): KeyValue[] {
    return Object.entries(laws).map(([key, value]) => ({ key, value }));
  }
}
